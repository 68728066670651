<template>
  <div class="hotels-container">
    <template v-if="allHotels && allHotels.length">
      <HotelCard
        v-for="(hotel, index) in allHotels"
        :key="`hotel-${index}`"
        :hotel="hotel"
      >
        <template v-slot:actions>
          <v-btn fab small color="error" @click="editHotel(hotel)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn fab small color="error" @click="deleteHotel(hotel)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </HotelCard>
    </template>
    <p v-else>No existing hotels. Please add using the form above.</p>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import HotelCard from '@/components/HotelCard'
export default {
  components: {
    HotelCard
  },
  computed: {
    ...mapGetters(['allHotels'])
  },
  async mounted () {
    try {
      await this.hotelsListener()
    } catch (err) {
      this.setGlobalNotification({ val: 'Error retrieving hotels.', type: 'error' })
    }
  },
  methods: {
    ...mapMutations(['setGlobalNotification']),
    ...mapActions(['hotelsListener', 'firestoreDeleteHotel']),
    deleteHotel (hotel) {
      if (confirm(`Are you sure you want to delete the hotel ${hotel.name}?`)) {
        this.firestoreDeleteHotel(hotel.id)
      }
    },
    editHotel (hotel) {
      this.$root.$emit('showHotelEditor', hotel)
    }
  }
}
</script>

<style>

</style>
