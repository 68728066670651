<template>
  <div class="locations-container">
    <template v-if="allLocations && allLocations.length">
      <draggable @change="orderChanged" v-model="allLocations">
        <v-card
          v-for="(location, index) in allLocations"
          :key="`location-${index}`"
          class="location-card my-5"
        >
          <v-card-text>
            <div class="left">
              <v-icon>mdi-drag</v-icon>
              <pre>{{ location.name }}</pre>
            </div>
            <div class="location__actions">
              <v-btn class="ml-3" color="error" x-small fab @click="editLocation(location)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn class="ml-3" color="error" x-small fab @click="deleteLocation(location)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </draggable>

      <v-btn v-if="locationsUpdated" large color="accent" @click="saveLocations">Save Order</v-btn>
    </template>
    <p v-else>No existing locations. Please add using the form above.</p>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { mapMutations, mapActions } from 'vuex'
export default {
  components: {
    draggable
  },
  data () {
    return {
      allLocationsInit: null,
      locationsUpdated: false
    }
  },
  computed: {
    allLocations: {
      get () {
        return this.$store.state.Locations.allLocations
      },
      set (value) {
        this.$store.commit('setAllLocations', value)
      }
    }
  },
  async mounted () {
    try {
      await this.locationsListener()
    } catch (err) {
      this.setGlobalNotification({ val: 'Error retrieving locations.', type: 'error' })
    }
  },
  methods: {
    ...mapMutations(['setGlobalNotification']),
    ...mapActions(['locationsListener', 'firestoreDeleteLocation', 'firestoreUpdateLocation']),

    async editLocation (location) {
      this.$root.$emit('showLocationEditor', location)
    },

    async saveLocations () {
      console.log('Saving location order...')
      if (confirm('Are you sure you want to save this location order?')) {
        try {
          // TODO: Consider checking to see if the index is different from init to cut down on writes
          this.allLocations.forEach(async (location, index) => {
            const loc = JSON.parse(JSON.stringify(location))
            loc.order = index
            await this.firestoreUpdateLocation(loc)
          })
          this.locationsUpdated = false
          this.setGlobalNotification({ val: 'Location order saved.' })
        } catch (err) {
          console.log(err)
          this.setGlobalNotification({ val: 'Error saving location order.', type: 'error' })
        }
      }
    },

    orderChanged () {
      this.locationsUpdated = true
    },

    deleteLocation (location) {
      if (confirm(`Are you sure you want to delete the location ${location.name}?`)) {
        this.firestoreDeleteLocation(location.id)
      }
    }
  },
  watch: {
    allLocations (val) {
      if (!this.allLocationsInit) this.allLocationsInit = val
    }
  }
}
</script>

<style lang="scss">
.location-card {
  cursor: grab;
  .v-card__text {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;
      gap: 12px;
    }
  }
}
</style>
