var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"authors-table"},[_c('h2',[_vm._v(" Authors "),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$root.$emit('showAuthorEditor')}}},[_vm._v(" New Author "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$root.$emit('showAuthorImporter')}}},[_vm._v(" Import "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-import")])],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.exportAuthors}},[_vm._v(" Export Authors to CSV "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-export")])],1)],1),(_vm.allAuthors)?_c('v-data-table',{attrs:{"headers":_vm.authorHeaders,"items":_vm.allAuthors,"sort-by":'date',"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{attrs:{"label":"Search","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.flags",fn:function(ref){
var item = ref.item;
return [(item.flags && item.flags.length > 0)?_c('v-icon',{attrs:{"color":"error","title":item.flags}},[_vm._v("mdi-alert")]):_vm._e()]}},{key:"item.associatedEmails",fn:function(ref){
var item = ref.item;
return _vm._l((item.associatedEmails),function(email,index){return _c('div',{key:((item.id) + "-email-" + index)},[_vm._v(_vm._s(email))])})}},{key:"item.authorLink",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":_vm.authorLink(item),"target":"_blank"}},[_vm._v(_vm._s(_vm.authorLink(item)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"pa-1",attrs:{"small":""},on:{"click":function($event){return _vm.copyAuthorLink(item)}}},[_vm._v("mdi-link")]),_c('v-icon',{staticClass:"pa-1",attrs:{"small":""},on:{"click":function($event){return _vm.editAuthor(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{staticClass:"pa-1",attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.deleteAuthor(item)}}},[_vm._v("mdi-delete")])]}}],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }