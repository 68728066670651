<template>
  <div class="authors-table">
    <h2>
      Ambassadors
      <v-btn class="mr-2" color="primary" @click="$root.$emit('showAmbassadorEditor')">
        New Ambassador
        <v-icon right>mdi-account-group</v-icon>
      </v-btn>
    </h2>
    <v-data-table
      v-if="allAmbassadors"
      :headers="headers"
      :items="allAmbassadors"
      :sort-by="'date'"
      :search="search"
    >
      <template v-slot:top>
        <v-text-field v-model="search" label="Search" append-icon="mdi-magnify" />
      </template>
      <template v-slot:[`item.ambassadorLink`]="{ item }">
        <a :href="ambassadorLink(item)" target="_blank">{{ ambassadorLink(item) }}</a>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small @click="copyLink(item)" class="pa-1">mdi-link</v-icon>
        <v-icon small @click="edit(item)" class="pa-1">mdi-pencil</v-icon>
        <v-icon small @click="deleteAmbassador(item)" color="error" class="pa-1">mdi-delete</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  data: () => ({
    search: '',
    headers: [
      {
        text: 'Last Name',
        value: 'lastName',
        width: '15%'
      },
      {
        text: 'First Name',
        value: 'firstName',
        width: '15%'
      },
      {
        text: 'Ambassador Link',
        value: 'ambassadorLink',
        width: '30%'
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        width: '104px'
      }
    ]
  }),

  methods: {
    ...mapMutations(['setGlobalNotification']),
    ...mapActions(['firestoreDeleteAmbassador']),

    ambassadorLink (ambassador) {
      return `${window.location.origin}/schedule?ambassador=${ambassador.id}`
    },

    copyLink (target) {
      navigator.clipboard.writeText(this.ambassadorLink(target))
      this.setGlobalNotification('Link copied to clipboard.')
    },

    edit (ambassador) {
      this.$root.$emit('showAmbassadorEditor', ambassador)
    },

    async deleteAmbassador (target) {
      if (confirm(`Are you sure you want to delete the ambassador ${target.firstName}?`)) {
        await this.firestoreDeleteAmbassador(target.id)
      }
    }
  },

  computed: {
    ...mapGetters(['allAmbassadors'])
  }
}
</script>

<style>

</style>
