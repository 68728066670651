<template>
  <div class="authors-table">
    <h2>
      Authors
      <v-btn class="mr-2" color="primary" @click="$root.$emit('showAuthorEditor')">
        New Author
        <v-icon right>mdi-plus</v-icon>
      </v-btn>
      <v-btn class="mr-2" color="primary" @click="$root.$emit('showAuthorImporter')">
        Import
        <v-icon right>mdi-import</v-icon>
      </v-btn>
      <v-btn color="primary" @click="exportAuthors">
        Export Authors to CSV
        <v-icon right>mdi-export</v-icon>
      </v-btn>
    </h2>
    <v-data-table
      v-if="allAuthors"
      :headers="authorHeaders"
      :items="allAuthors"
      :sort-by="'date'"
      :search="search"
    >
      <template v-slot:top>
        <v-text-field v-model="search" label="Search" append-icon="mdi-magnify" />
      </template>
      <template v-slot:[`item.flags`] = "{ item }">
        <v-icon
          v-if="item.flags && item.flags.length > 0"
          color="error"
          :title="item.flags"
        >mdi-alert</v-icon>
      </template>
      <template v-slot:[`item.associatedEmails`]="{ item }">
        <div v-for="(email, index) in item.associatedEmails" :key="`${item.id}-email-${index}`">{{ email }}</div>
      </template>
      <template v-slot:[`item.authorLink`]="{ item }">
        <a :href="authorLink(item)" target="_blank">{{ authorLink(item) }}</a>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small @click="copyAuthorLink(item)" class="pa-1">mdi-link</v-icon>
        <v-icon small @click="editAuthor(item)" class="pa-1">mdi-pencil</v-icon>
        <v-icon small @click="deleteAuthor(item)" color="error" class="pa-1">mdi-delete</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Papa from 'papaparse'

export default {
  data: () => ({
    search: '',
    authorHeaders: [
      {
        text: 'Flags',
        value: 'flags',
        width: '10%'
      },
      {
        text: 'Last Name',
        value: 'lastName',
        width: '15%'
      },
      {
        text: 'First Name',
        value: 'firstName',
        width: '15%'
      },
      {
        text: 'Emails',
        value: 'associatedEmails'
      },
      {
        text: 'Author Link',
        value: 'authorLink',
        width: '30%'
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        width: '104px'
      }
    ]
  }),

  methods: {
    ...mapMutations(['setGlobalNotification']),
    ...mapActions(['firestoreDeleteAuthor']),

    authorLink (author) {
      return `${window.location.origin}/schedule?author=${author.id}`
    },

    copyAuthorLink (author) {
      navigator.clipboard.writeText(this.authorLink(author))
      this.setGlobalNotification('Author link copied to clipboard.')
    },

    editAuthor (author) {
      this.$root.$emit('showAuthorEditor', author)
    },

    async deleteAuthor (author) {
      if (confirm(`Are you sure you want to delete the author ${author.fullName}?`)) {
        await this.firestoreDeleteAuthor(author.id)
      }
    },

    formatForCsvExport (inputData) {
      const authorsData = JSON.parse(JSON.stringify(inputData))
      authorsData.forEach((author) => {
        // console.log(author)
        delete author.fullName
        delete author.flags

        if (author.associatedEmails && author.associatedEmails.length) {
          let formatted = ''
          author.associatedEmails.forEach((email, index) => {
            if (index > 0) {
              formatted += '\n' // add line break after the first entry
            }
            formatted += email
          })
          author.associatedEmails = formatted
        }

        if (author.booksSold && author.booksSold.length) {
          let formatted = ''
          author.booksSold.forEach((book, index) => {
            if (index > 0) {
              formatted += '\n' // add line break after the first entry
            }
            formatted += book
          })
          author.booksSold = formatted
        }

        if (author.onsiteContacts && author.onsiteContacts.length) {
          let formatted = ''
          author.onsiteContacts.forEach((contact, index) => {
            if (index > 0) {
              formatted += '\n' // add line break after the first entry
            }
            formatted += `${contact.name || 'No name'}${contact.email ? ', ' + contact.email : ''}${contact.phone ? ', ' + contact.phone : ''}`
          })
          author.onsiteContacts = formatted
        }
      })
      return authorsData
    },

    exportAuthors () {
      console.log('Exporting authors to CSV...')
      // Format the data and convert to CSV
      const authorsFormatted = this.formatForCsvExport(this.allAuthors)
      // console.log(authorsFormatted)
      const csvData = Papa.unparse(authorsFormatted)
      // Now convert to file to download.
      const blob = new Blob([csvData], { type: 'text/csv' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = 'data.csv'
      link.click()
    }
  },

  computed: {
    ...mapGetters(['allAuthors'])
  }
}
</script>

<style>

</style>
